import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { getObjectiveById } from "../../utils/dataRetrieval";
import { getCurrentUser, setNewCurrentUser } from "../../utils/localStorage";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
// Contexts
import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";
import { banditManchotStore } from "../../contexts/BanditManchotContext";
// Assets
import BotLogo from '../../assets/test/Avatar.svg';
import './ModuleListStudent.scss';
import parse from "html-react-parser";

function HomeStudent() {
  const [hasSuccessRate, setHasSuccessRate] = useState(false);
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);
  const { banditManchot, setBanditManchot } = useContext(banditManchotStore);
  const history = useHistory();


  useEffect(() => {
    const currentUser = getCurrentUser();
    if (!currentUser) setNewCurrentUser("toto");
    if (
      Object.keys(banditManchot).some(
        (moduleId) => !banditManchot[moduleId].historyLoaded
      )
    ) {
      const bmSetAsLoaded = BanditManchotWhisperer.loadAllModulesHistory(
        data.modules.map(module => module.id),
        getCurrentUser()!.history,
        banditManchot
      );
      setBanditManchot(bmSetAsLoaded);
    }
    ///
  },[])


  const getModuleTitle = (id:string) => {
    let isModule = data.modules.find(m => m.id === id)
    if(isModule) {
      return isModule.description.$html
    } else {
      return 'no title'
    }
  }

  const cards = Object.keys(banditManchot).map(key => {
    const studentProgress = BanditManchotWhisperer.getStudentModuleProgression(banditManchot[key].instance);
   
    const currentUser = localStorage.getItem("currentUser");
    let successRate = null;

    if (currentUser) {      
      const parsedCurrentUser = JSON.parse(currentUser);
      if (parsedCurrentUser.history[key] && parsedCurrentUser.history[key].length >= 5) {
        const slicedHistory = parsedCurrentUser.history[key].slice(parsedCurrentUser.history[key].length - 5, parsedCurrentUser.history[key].length)
        successRate = slicedHistory.reduce((acc: number, cur: {score: number}) => {
          return Math.round(cur.score) + acc
        }, 0)
        if (hasSuccessRate === false) {
          setHasSuccessRate(successRate === null ? false : true)
        }
      }
    }

    return (
      <div className="card" key={`card-${key}`}>
        <div className={`title ${studentProgress ? 'active' : ''}`}>{getModuleTitle(key)}</div>
        <div className="card-content">
          <div className="bar-container">
            <div className={`bar ${studentProgress ? 'progress-active' : ''}`}>
              <div className="progress-bar" style={{ width: `${studentProgress}%`}}>
                <div className="text-content">{config.i18n.moduleList.student.progression}</div>
              </div>
            </div>
            {successRate !== null ?
            <div className="bar success-rate-active">
              <div className="text-content">{config.i18n.moduleList.student.successRate}</div>
              <span>{`${successRate * 20}%`}</span>
            </div>
              : 
            <div className="bar">
              <div className="text-content">{config.i18n.moduleList.student.successRate}</div>
            </div>
            }
          </div>
        </div>
        <div className="card-modal">
          <button data-key={key} onClick={() => { history.push("/player/bandit-manchot/" + key); }}className="btn-modal">{successRate !== null ? config.i18n.moduleList.student.continue : config.i18n.moduleList.student.start}</button>
        </div>
      </div>
      )
    }
  )

  return (
    <div id="module-list">
      <div id="chatbot-feedback">
        <img className="chatbot-img" src={BotLogo} alt="chatbot icon"/>
        <div className="text-content">
          { hasSuccessRate ? parse(config.i18n.moduleList.student.message.visited.$html)
            : parse(config.i18n.moduleList.student.message.default.$html)
            }
        </div>
      </div>
      <div id="card-container">
        {cards}
      </div>
    </div>
  )
}

export default HomeStudent;