import { HistoryItem } from "@evidenceb/bandit-manchot";

interface LocalStorage {
  currentUser: {
    id: string; // TODO: DAVY: Tu peux retoucher le typage du token si besoin!
    history: {
      [moduleId: string]: HistoryItem[];
    }
  }
}

export const CURRENT_USER_KEY = "currentUser";

export default LocalStorage;