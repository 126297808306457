import { HtmlString } from "@evidenceb/gameplay-interfaces";
import { Config as AiConfig } from "@evidenceb/bandit-manchot";

export enum Pagetype {
    HOME = "HOME",
    MODULELIST = "MODULELIST",
    DASHBOARD = "DASHBOARD",
    LOGOUT = "LOGOUT",
    CONTENTPAGE = "CONTENTPAGE",
}

export interface Title {
    title: string;
}

export interface Page {
    url: string;
    label: string;
    type: Pagetype;
    inTopNav: boolean;
    inFooter: boolean;
}

export interface Config {
    version: number;
    client_name: string;
    activate_dashboard: boolean;
    activate_contentPage: boolean;
    pages: Page[];
    i18n: {
        hierarchy: {
            module: {
                full: string;
                short: string;
            };
            objective: {
                full: string;
                short: string;
            };
            activity: {
                full: string;
                short: string;
            };
            exercise: {
                full: string;
                short: string;
            };
        };
        moduleList: {
            info: string;
            title: string;
            student:{
                message:{
                    default: HtmlString;
                    visited: HtmlString;
                },
                progression:string;
                successRate:string;
                start:string;
                continue:string;
            }
        };
        endPlaylist: {
            congratulations: string;
            completed: string;
            result: string;
            exerciseCorrect: string;
            exerciseIncorrect: string;
            moduleList: string;
            nextModule: string;
            dashboard: string;
        };
        exerciseShell: {
            close: string;
            feedback: {
                nextExercise: string;
                nextActivity: string;
                endPlaylist: string;
                retry: string;
            };
            tryAgain: HtmlString;
        };
        misc: {
            logout: string;
            contact: string;
            developedBy: string;
            colon: string;
        };
        chatbot: {
            welcomeMessages: HtmlString[];
            start: HtmlString;
            initialTestMessages: {
                start: HtmlString;
                end: HtmlString;
            };
        };
        gameplays: {
            generic: {
                btnValidate: string;
            };
            [gameplayType: string]: {
                [key: string]: string;
            };
        };
        drawer: {
            targetedDifficulties: string;
        };
    };
    logos: {
        header_client: string;
        endPlaylistIcon: string;
        footer_evidenceb: string;
        footer_client: string;
    };
    contact: string;
    ai?: AiConfig;
}
