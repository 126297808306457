import { Config } from "../interfaces/Config";

const aiConfig: Omit<Required<Config>["ai"], "instances"> = {
    id: "zpdes",
    baseConfig: {
        algo_name: "ZpdesHssbg",
        graph: {
            file_name: "",
            path: "",
        },
        ZpdesSsbg: {
            ZpdesSsb: {
                filter1: 0.2,
                filter2: 0.8,
                uniformval: 0.05,
                stepUpdate: 8,
                stepMax: 4,
                nbMinStep: 4,
                upZPDval: 0.75,
                deactZPDval: 0.9,
                promote_coeff: 1,
                thresHProm: 0.75,
                thresHDeact: 0.9,
                h_promote_coeff: 1,
                dashboard: {
                    thresPos: 0.1,
                    thresNeg: 0.1,
                },
            },
        },
    },
    initialTest: {
        "1b0ed844-31bb-4fc6-94e8-2df42b78271e": {
            activityId: "4113dec3-47ae-44d7-b1dd-7ab3a192c2b9",
            objectiveId: "f84527ad-8284-460b-9237-cfc5df17f001",
        },
        "b367e3af-26e6-4053-96b2-76b2147c6a0d": {
            activityId: "705d074c-f38c-4d0e-99bd-09a9a846f5c0",
            objectiveId: "61c63821-5d14-41d7-8b67-06f906d54a14",
        },
        "4c798961-2f51-4c4c-ad8c-d3b6b527ccd8": {
            activityId: "58d8078b-78d3-42be-8d42-931641480f6c",
            objectiveId: "cbd463a3-7daf-415a-82b6-94280ed3701c",
        },
    },
    moduleConfig: {
        "1b0ed844-31bb-4fc6-94e8-2df42b78271e": {
            act_prime: "1b0ed844-31bb-4fc6-94e8-2df42b78271e",
            "1b0ed844-31bb-4fc6-94e8-2df42b78271e": {
                ordered: [1],
                activation_h: [1],
                stepUpdate: 8,
                stepMax: 4,
                nbMinStep: 4,
                upZPDval: 0.75,
                deactZPDval: 0.9,
                promote_coeff: 1,
                subgroups: [
                    [
                        "8f6c4c01-734e-4f69-8e7a-fe5c066fe342",
                        "60cb0ac2-e6d4-4cca-bcdf-906cf4ce30dc",
                        "22ef075c-7ce4-48d4-80f8-4e5d8b28453f",
                        "8b5cc3b1-5d80-48b3-b732-5ccd7f0fa073",
                        "1656c23b-a1e4-4523-bb27-7473f023738f",
                        "ca0a83d7-d08b-41eb-87a8-0f0170931e4b",
                        "8a65299b-f068-46f1-ba93-daf2966decf0",
                        "98b0cf89-2a66-4be7-b264-6971541222b7",
                        "39667b48-8f76-4e7e-b2d5-687d5c8afe18",
                        "5f4ff98b-ef10-4bbc-a06b-dba44c06a0ab",
                        "989cafc4-4014-4862-b247-e33cef7a52f2",
                        "787211f6-6ca3-42a1-9eed-240a3831b37e",
                        "35e805a0-17d5-46bb-883b-6ca871b4bec8",
                        "9ec0a6c9-f45d-4b76-acb0-09963bd64d5a",
                        "fd9fe14b-3021-412f-8be4-dc2f2d78f4b0",
                    ],
                ],
                init_ssb: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                requirements: [{}],
                nb_stay: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
            },
            "8f6c4c01-734e-4f69-8e7a-fe5c066fe342": {
                ordered: [1],
                subgroups: [
                    [
                        "3420ea09-7c67-4708-b746-5af961c76177",
                        "0d46da2d-89d6-4f04-bac8-1bd7f37a5e92",
                        "d48a123d-827c-4b50-8102-62361be549ba",
                        "1572468b-081a-4c43-9324-df4b1b2134fc",
                        "90906eb9-d7ee-4478-9fb5-f9d008503af1",
                        "3b415f5b-0a98-4408-8b37-c27b1b0e064a",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "60cb0ac2-e6d4-4cca-bcdf-906cf4ce30dc": {
                ordered: [1],
                subgroups: [
                    [
                        "7566094c-c234-44d5-8255-7dba39f0fe64",
                        "a66d6ef6-56c7-45dc-a825-21b935d79c96",
                        "3e068c2f-537d-4222-a668-4448bf3c0f77",
                        "2e641c85-e527-4144-93fa-105ebdc2e85b",
                        "d15a99b8-a241-49aa-856a-d5d920594d5b",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "22ef075c-7ce4-48d4-80f8-4e5d8b28453f": {
                ordered: [1],
                subgroups: [
                    [
                        "fc2bf804-dd4c-458a-82eb-657da647567e",
                        "68238484-ec5a-4d25-8ea1-bf66b78c909c",
                        "b0307564-73d1-4afc-8a3c-2a2a132eb57f",
                        "e8348f62-30f8-49e7-b669-42111ddd8aee",
                        "1d9c0b09-7d94-46b4-b960-afe93700734a",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "8b5cc3b1-5d80-48b3-b732-5ccd7f0fa073": {
                ordered: [1],
                subgroups: [
                    [
                        "758381e6-5360-4047-b7f6-4442ca8ab6bc",
                        "6a6030f8-27fc-46c2-af75-e75cdf178568",
                        "3fe447b2-fbe4-44e4-99ec-888be8217ec8",
                        "d4e277f3-6470-4a91-907e-5b7c6eed1aeb",
                        "3ca57a1e-7c85-4b84-ac6a-b0cee8d164a3",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "1656c23b-a1e4-4523-bb27-7473f023738f": {
                ordered: [1],
                subgroups: [
                    [
                        "2e7e9d12-4125-4977-a333-f117123219ef",
                        "38e8a2e3-1719-41b9-a584-424b5afd61fa",
                        "c2201190-279e-461c-826a-3c5a0a209891",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "ca0a83d7-d08b-41eb-87a8-0f0170931e4b": {
                ordered: [1],
                subgroups: [
                    [
                        "c7113e55-14b9-4eca-a261-8224c02017a5",
                        "fb5586a9-72e6-4b25-8e0e-5f32b85a83d0",
                        "91099c71-b196-4a33-a532-d1d16e3182af",
                        "4b45fbce-0966-438b-9f55-f6323195a185",
                        "a98d0d67-a92d-42c9-bb4a-5e1b321a6d7e",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "8a65299b-f068-46f1-ba93-daf2966decf0": {
                ordered: [1],
                subgroups: [
                    [
                        "7a8c5ff5-820a-44da-87c1-95c4fcb7785f",
                        "e970b645-7071-41ca-9f8e-2a9fc4950e50",
                        "0ba20916-e635-40db-95ea-a16678b70179",
                        "67f33ca5-b01a-4485-85a2-3ac05c822de9",
                        "4c41b11a-e722-42a6-9d78-504977f58eed",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "98b0cf89-2a66-4be7-b264-6971541222b7": {
                ordered: [1],
                subgroups: [
                    [
                        "61e83970-dcb2-4c76-b3fa-657696885d5c",
                        "759ac305-2741-41d9-9a5d-61fb278524b5",
                        "9ed468cd-a138-4009-a8ac-edb1ba37cce0",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "39667b48-8f76-4e7e-b2d5-687d5c8afe18": {
                ordered: [1],
                subgroups: [
                    [
                        "aecfb728-5484-4d74-88f0-0e8f140e4427",
                        "befcada9-4eac-45dd-8ea1-2f18e43f644a",
                        "ed280d11-d0f8-467f-8c0a-55dfa49cecf1",
                        "5be3a35f-ec63-4dd6-b918-9320e5977ce3",
                        "53a1a320-98cd-4aeb-a7a6-dbf3b3525c87",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "5f4ff98b-ef10-4bbc-a06b-dba44c06a0ab": {
                ordered: [1],
                subgroups: [
                    [
                        "fc20ad40-b0d3-4ff8-91d6-6e546c80189c",
                        "756f362f-efc3-47fe-b16a-8baf942892f8",
                        "97537c86-1ed2-4952-a2ae-d8ac46f0ca0f",
                        "5f7c8aa1-fbca-494d-ad9e-bcb26de8d156",
                        "78a5e8e0-12ff-4daf-a8f9-d949869939e9",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "989cafc4-4014-4862-b247-e33cef7a52f2": {
                ordered: [1],
                subgroups: [
                    [
                        "df36d6d9-4d8e-484d-af15-a2bd01367a84",
                        "c05bb531-d398-458f-bc31-9ab38f9ac9ef",
                        "2569b7bd-7552-4030-906f-87ac3cbf98d5",
                        "64da4ada-00d7-4e36-973b-849dc23fb49a",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "787211f6-6ca3-42a1-9eed-240a3831b37e": {
                ordered: [1],
                subgroups: [
                    [
                        "355459a4-8108-4e4e-8dc7-ab09d8698745",
                        "d533acca-bc2c-404d-beb7-f183aae2a559",
                        "e816339d-aef3-4e4d-84a5-c2812d15c229",
                        "55b2a1e3-9494-4052-85d0-ac687f0f8a59",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "35e805a0-17d5-46bb-883b-6ca871b4bec8": {
                ordered: [1],
                subgroups: [
                    [
                        "5fce0d84-dfa5-443b-b8e0-9c3536991a27",
                        "a82740a5-3e09-4826-a89a-a251e7694d3c",
                        "5f5f671f-0738-4b0f-a9a7-94fcb1304914",
                        "3a54457d-8f8b-4baa-9d50-3d738bd7b00a",
                        "d2ad3a15-7e24-4366-9093-358826da2efb",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "9ec0a6c9-f45d-4b76-acb0-09963bd64d5a": {
                ordered: [1],
                subgroups: [
                    [
                        "26592dc7-ced2-44cb-a9cc-acc31bd4da74",
                        "05d8b4c8-fb53-4ba8-b3b3-3bdf21dfa66f",
                        "1b6fe257-1f53-4a99-96b8-75fa9783c4da",
                        "d9dad4fb-4352-4b0a-bd34-f329d4e42ae7",
                        "1e3fb16e-8bde-481b-8081-b43248cda25e",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "fd9fe14b-3021-412f-8be4-dc2f2d78f4b0": {
                ordered: [1],
                subgroups: [
                    [
                        "cc7ec02a-aa98-40be-b0df-23a196c6540f",
                        "bc540a08-5dbf-413c-8d21-14a47284341c",
                        "72486c3d-63f0-448b-9304-859a0cf9f0c0",
                        "efb99dca-23ce-49b5-87ed-c5b9d7074784",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
        },
        "b367e3af-26e6-4053-96b2-76b2147c6a0d": {
            act_prime: "b367e3af-26e6-4053-96b2-76b2147c6a0d",
            "b367e3af-26e6-4053-96b2-76b2147c6a0d": {
                ordered: [1],
                activation_h: [1],
                stepUpdate: 8,
                stepMax: 4,
                nbMinStep: 4,
                upZPDval: 0.75,
                deactZPDval: 0.9,
                promote_coeff: 1,
                subgroups: [
                    [
                        "74cae985-7d7d-475f-8fde-f22306aa85c2",
                        "6926062e-a651-4a58-a0fe-6de53bfb1630",
                        "7424fda0-6dc1-41b5-952e-f377867b2c66",
                        "e35d61a0-fe1c-4a10-91f4-673c8f19c08b",
                        "d9f83466-b3bf-470c-8a62-0c49bd385622",
                        "9a8c2804-23bd-4819-b858-2f7d51758396",
                    ],
                ],
                init_ssb: [0, 1, 2, 3, 4, 5],
                requirements: [{}],
                nb_stay: [3, 3, 3, 3, 3, 3],
            },
            "74cae985-7d7d-475f-8fde-f22306aa85c2": {
                ordered: [1],
                subgroups: [
                    [
                        "2da536b7-2559-4eef-b3b1-ce48f94ad6ff",
                        "5c71df21-bb21-489b-93c3-d6f0adf0aef9",
                        "f612b03f-f19c-4549-a0bb-f123603d065e",
                        "aea8f541-04b8-433e-a9de-b6ec990fd37d",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "6926062e-a651-4a58-a0fe-6de53bfb1630": {
                ordered: [1],
                subgroups: [
                    [
                        "9b9fcc4f-9883-4ff7-9905-75d618cac8ed",
                        "e22d514b-5a39-4065-8342-272bebb54d91",
                        "f67a3e63-ae79-4a9e-8fa6-b59683fca282",
                        "e57eeb62-ce6d-436a-b137-0f3c4b75a3cc",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "7424fda0-6dc1-41b5-952e-f377867b2c66": {
                ordered: [1],
                subgroups: [
                    [
                        "60f85e6a-25ae-4966-842d-689b85393d18",
                        "5a039da5-2d03-4206-8ea2-40ebc7e30810",
                        "3a7fdd12-ea2b-4d8a-ad57-f11f13287eb9",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "e35d61a0-fe1c-4a10-91f4-673c8f19c08b": {
                ordered: [1],
                subgroups: [
                    [
                        "043ff086-9935-4d72-9acd-155e547f60f7",
                        "bd0d9a01-338a-4157-9c7e-9dca64a2b2fc",
                        "04c8f44e-0349-4c06-bbd0-5ce1a57c87ae",
                        "50f23633-496a-4b97-9140-d5169c18bd3e",
                        "e8185488-b608-429d-b5ea-8dc1a3c1aed3",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "d9f83466-b3bf-470c-8a62-0c49bd385622": {
                ordered: [1],
                subgroups: [
                    [
                        "df89f89e-6159-426d-a0bf-a7286da85d37",
                        "cf3cdb14-00b6-49ef-9f1a-46bcd0332a4c",
                        "e384e580-b6fd-44dd-bc59-d006c940c489",
                        "1b3ed4ff-9ab4-4517-97a4-3258878eb5b9",
                        "b19cc342-0378-4191-8131-e778eb83a9af",
                        "3130fc55-44d3-4687-bca9-ac360ae0a8d7",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "9a8c2804-23bd-4819-b858-2f7d51758396": {
                ordered: [1],
                subgroups: [
                    [
                        "73fef862-837b-482e-8514-86506dc90533",
                        "bcf28b55-5a27-42c9-913e-222747ecafe2",
                        "f03315ca-0c01-46a8-871f-a4b9a850bd26",
                        "44967521-b43e-4167-9a4c-ed3ec019d8d8",
                        "7ae9cdef-eaba-4764-8675-8e79ac92796d",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
        },
        "6496327d-1e05-45f6-a13c-2db8b520edf4": {
            act_prime: "6496327d-1e05-45f6-a13c-2db8b520edf4",
            "6496327d-1e05-45f6-a13c-2db8b520edf4": {
                ordered: [1],
                activation_h: [1],
                stepUpdate: 8,
                stepMax: 4,
                nbMinStep: 4,
                upZPDval: 0.75,
                deactZPDval: 0.9,
                promote_coeff: 1,
                subgroups: [[]],
                init_ssb: [],
                requirements: [{}],
                nb_stay: [],
            },
        },
        "f27c791e-27ea-4fb4-95c9-ea60618eaeb8": {
            act_prime: "f27c791e-27ea-4fb4-95c9-ea60618eaeb8",
            "f27c791e-27ea-4fb4-95c9-ea60618eaeb8": {
                ordered: [1],
                activation_h: [1],
                stepUpdate: 8,
                stepMax: 4,
                nbMinStep: 4,
                upZPDval: 0.75,
                deactZPDval: 0.9,
                promote_coeff: 1,
                subgroups: [[]],
                init_ssb: [],
                requirements: [{}],
                nb_stay: [],
            },
        },
        "4c798961-2f51-4c4c-ad8c-d3b6b527ccd8": {
            act_prime: "4c798961-2f51-4c4c-ad8c-d3b6b527ccd8",
            "4c798961-2f51-4c4c-ad8c-d3b6b527ccd8": {
                ordered: [1],
                activation_h: [1],
                stepUpdate: 8,
                stepMax: 4,
                nbMinStep: 4,
                upZPDval: 0.75,
                deactZPDval: 0.9,
                promote_coeff: 1,
                subgroups: [
                    [
                        "47e8c328-6334-4acb-8f36-09a2cd085bf4",
                        "a40f1aba-fc11-44f7-97b6-8d0156ffb8b6",
                        "eee0e555-8372-4ce3-8aab-e87b6574fef1",
                        "e1e10d95-718a-48cc-9fe7-e73206d4bfb4",
                        "84218ec6-0174-4502-985a-0e62caccad21",
                        "b64f76ef-3e41-4e6f-a5b6-a6c83d5017f2",
                    ],
                ],
                init_ssb: [0, 1, 2, 3, 4, 5],
                requirements: [{}],
                nb_stay: [3, 3, 3, 3, 3, 3],
            },
            "47e8c328-6334-4acb-8f36-09a2cd085bf4": {
                ordered: [1],
                subgroups: [
                    [
                        "9fbe683e-3eec-4e6a-b7be-4ee744a47c62",
                        "0707d826-f90b-48d7-8eed-dbc9ed64b56c",
                        "a6493067-49ff-41cd-876c-e148ba100868",
                        "3da969b6-27a7-4b62-ae30-bfa87e0dc790",
                        "4d993af1-74a6-4e24-90ab-78bd6d2243ce",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "a40f1aba-fc11-44f7-97b6-8d0156ffb8b6": {
                ordered: [1],
                subgroups: [
                    [
                        "66ddf335-4a9e-48de-8ef6-7c4179d94701",
                        "b40e9cac-9372-4d5c-81cb-37d2e5902856",
                        "437154a1-30dd-4a99-8257-dc9ded713486",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "eee0e555-8372-4ce3-8aab-e87b6574fef1": {
                ordered: [1],
                subgroups: [
                    [
                        "b9d3b45b-a7b1-4dac-a53d-120017144b90",
                        "33a7cbff-a423-44bb-86ae-fb70fa12dddc",
                        "32ddef00-c2f4-4845-9e95-d41905964898",
                        "8ba5c01d-c42d-4ae8-8ce5-71a719387fc6",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "e1e10d95-718a-48cc-9fe7-e73206d4bfb4": {
                ordered: [1],
                subgroups: [
                    [
                        "f8a9f65e-bd3b-46bc-a1b5-e103f4384006",
                        "a02ee95d-bd4e-45dc-ab3b-9c2ae151a61a",
                        "c8f14f69-d42e-44e8-80d3-e831dd8144bd",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "84218ec6-0174-4502-985a-0e62caccad21": {
                ordered: [1],
                subgroups: [
                    [
                        "fec10d5f-e500-411f-9ce9-a8fc761a320e",
                        "fe1d82a5-185d-49b4-9bd1-c0b51c8fa02f",
                        "7ddc4c68-cabd-4733-a35e-9fa680c60743",
                        "f5f05d67-f8a9-4670-afe8-dcbf89b7b200",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
            "b64f76ef-3e41-4e6f-a5b6-a6c83d5017f2": {
                ordered: [1],
                subgroups: [
                    [
                        "a85f5b30-04ac-4119-b055-dbad2349f614",
                        "2a77abff-ae9d-4f95-9f34-7f99fa0b5c6d",
                        "fa616e63-2466-4838-b58e-22f1d567ef0b",
                        "201d6df5-497e-4468-95b8-d5f75d06f50b",
                    ],
                ],
                upZPDval: 0.75,
                promote_coeff: 1,
                init_ssb: [[0]],
                deactZPDval: 0.9,
            },
        },
    },
};

export default aiConfig;
