import React, {useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext"
// Assets
import BotLogo from '../../assets/test/Avatar.svg';
import './HomeStudent.scss';
import { set } from 'lodash';
import { banditManchotStore } from '../../contexts/BanditManchotContext';
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";


function HomeStudent () {
  const [hasSavedProgress, setHasSavedProgress] = useState<boolean>(false);
  const { config } = useContext(configStore);
  const { data } = useContext(dataStore);
  const { setBanditManchot } = useContext(banditManchotStore);
  const history = useHistory();
  
  useEffect(() => {
    if (localStorage.getItem('currentUser')) {
      setHasSavedProgress(true)
    } else {
      setHasSavedProgress(false)
    }
  }, [])

  const startOver = async () => {
    localStorage.removeItem('currentUser')
    setHasSavedProgress(false);
    const banditManchot =  await BanditManchotWhisperer.initBanditManchot(config.ai!, data);
    setBanditManchot(banditManchot);
    return console.log("restart click!")
  }

  const startLink = () => {
    
    
    let moduleListPage = config.pages.find((page) => (
      page.type === "MODULELIST"
    ))

    if (moduleListPage) {
      history.push(`/${moduleListPage.url}`);
    } else {
      return ''
    }
  }

  return (
    <div id="home-student">
      <div className="left-container">
        <h1>Bienvenue sur <br/> Adaptiv'Langue</h1>

        {hasSavedProgress ?
        <div className="progress-container">
            <p>On dirait qu’une progression est déjà enregistrée. Que voulez-vous faire ?</p>
          <div className="btn-container">
            <button className="btn btn-restart" onClick={startOver}>Recommencer</button>
              <button className="btn btn-start btn-right" onClick={startLink}>Reprendre ma progression <span className="material-icons">arrow_forward</span></button>
          </div>
        </div> :
        <div className="progress-container">
          <p>Commencez dès maintenant votre parcours d'apprentissage personnalisé, pour mieux maitriser la langue française.</p>
          <div className="btn-container">
            <button className="btn btn-start" onClick={startLink}>Commencer <span className="material-icons">arrow_forward</span></button>
          </div>
        </div>
        }

      </div>
      <div className="right-container">
        <img src={BotLogo} alt="Chatbot Logo"/>
      </div>
    </div>
  )
}

export default HomeStudent