import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { BanditManchot } from "@evidenceb/bandit-manchot";

// Context Typing
interface BanditManchotContext {
    banditManchot: BanditManchot;
    setBanditManchot: Dispatch<SetStateAction<BanditManchot>>;
}

// Initial State
const initialState: BanditManchot = {};

// Context init
const banditManchotStore = createContext<BanditManchotContext>({
    banditManchot: initialState,
    setBanditManchot: () => null,
});

// Provider init
const { Provider } = banditManchotStore;

// Final setup
const BanditManchotProvider = ({ children }: { children: JSX.Element }) => {
    const [banditManchot, setBanditManchot] = useState(initialState);
    return <Provider value={{ banditManchot, setBanditManchot }}>{children}</Provider>;
};

export { banditManchotStore, BanditManchotProvider };
