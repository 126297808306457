import { CurrentUserNotFoundError } from "../errors";
import { HistoryItem } from "@evidenceb/bandit-manchot";
import LocalStorage, { CURRENT_USER_KEY } from "../interfaces/LocalStorage";

export const getCurrentUser = (): LocalStorage["currentUser"] | undefined => {
    const rawCurrentUser = localStorage.getItem(CURRENT_USER_KEY);
    if (!rawCurrentUser) return undefined;
    return JSON.parse(rawCurrentUser) as LocalStorage["currentUser"];
};

export const setNewCurrentUser = (id: string): void => {
    localStorage.setItem(
        CURRENT_USER_KEY,
        JSON.stringify({
            id,
            history: {},
        })
    );
};

export const getModuleHistory = (moduleId: string): HistoryItem[] => {
    const currentUser = getCurrentUser();
    if (!currentUser) throw new CurrentUserNotFoundError();
    return currentUser.history[moduleId] ?? [];
};

export const updateModuleHistory = (
    moduleId: string,
    historyItem: HistoryItem
): void => {
    const currentUser = getCurrentUser();
    if (!currentUser) throw new CurrentUserNotFoundError();
    const updatedCurrentUser = {
        ...currentUser,
        history: {
            ...currentUser.history,
            [moduleId]: currentUser.history[moduleId]
                ? [...currentUser.history[moduleId], historyItem]
                : [historyItem],
        },
    };
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(updatedCurrentUser));
};
