import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import ModuleListItem from "../../components/ModuleListItem/ModuleListItem";
import "./ModuleList.scss";

import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";

import { Title } from "../../interfaces/Config";
import { getObjectiveById } from "../../utils/dataRetrieval";
import { getCurrentUser, setNewCurrentUser } from "../../utils/localStorage";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import { banditManchotStore } from "../../contexts/BanditManchotContext";

function ModuleList({ title }: Title) {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);

    // TEMP: Placeholder while their is no actual user id retrieval
    // TODO: Davy, il faudra mettre le vrai dans le local storage comme ca, a l'endroit ou tu veux
    const currentUser = getCurrentUser();
    if (!currentUser) setNewCurrentUser("toto");
    const { banditManchot, setBanditManchot } = useContext(banditManchotStore);
    if (
        Object.keys(banditManchot).some(
            (moduleId) => !banditManchot[moduleId].historyLoaded
        )
    ) {
        const loadedBanditManchot = BanditManchotWhisperer.loadAllModulesHistory(
            data.modules.map(module => module.id),
            getCurrentUser()!.history,
            banditManchot 
        );
        setBanditManchot(loadedBanditManchot);
    }
    ///

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div className="modules-list">
                <h1>{config.i18n.moduleList.title}</h1>
                {config.i18n.moduleList.info !== "" ? (
                    <div className="info">
                        <p>{config.i18n.moduleList.info}</p>
                    </div>
                ) : null}

                {data.modules.map((module, i) => (
                    <ModuleListItem
                        key={"item" + i}
                        id={module.id}
                        title={module.title.long}
                        description={module.description}
                        objectives={module.objectiveIds.map((objectiveId) =>
                            getObjectiveById(objectiveId, data, false)
                        )}
                    />
                ))}
            </div>
        </>
    );
}

export default ModuleList;
