import { useContext, useEffect, useState } from "react";
import {
    ComingNext,
    Exercise,
    ExerciseResult,
    PlaylistExecutionStage,
    PlaylistManager,
    Hierarchy,
    HierarchyIds,
    Playlist,
} from "@evidenceb/gameplay-interfaces";
import { dataStore } from "../contexts/DataContext";
import {
    BanditManchotInstanceNotFoundError,
    PlayerProgressionError,
} from "../errors";
import {
    getHierarchyFromHierarchyId,
    getRandomExercise,
} from "../utils/dataRetrieval";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import * as localStorageUtils from "../utils/localStorage";
import { banditManchotStore } from "../contexts/BanditManchotContext";
import * as Sentry from "@sentry/react";
import { CaptureContext } from "@sentry/types";

/**
 * This is the Bandit Manchot playlist manager. It uses the Bandit Manchot AI
 * to determine which are the exercises that are played. A Bandot Manchot user
 * should:
 * - not be able to navigate freely between exercises
 * - be assigned their next exercise depending on their history by the Bandit
 *   Manchot AI
 * - receive incentive messages periodically
 * - have their history stored locally
 * - start with a clean conversation (without their past responses) when
 *   picking up an already started module
 */
// TODO:
// - Add incentive messages

interface BMPlaylistManager extends PlaylistManager {
    playlist: Playlist & { isInitialTest: boolean };
    clearHistory: () => void;
}

const useBanditManchotPlaylistManager = (
    moduleId: string
): BMPlaylistManager => {
    const { data } = useContext(dataStore);

    // Init bandit manchot
    const { banditManchot, setBanditManchot } = useContext(banditManchotStore);
    if (!banditManchot[moduleId])
        throw new BanditManchotInstanceNotFoundError();
    let nextHierarchyIds: HierarchyIds & { isInitialTest: boolean };
    if (!banditManchot[moduleId].historyLoaded) {
        const history = localStorageUtils.getModuleHistory(moduleId);
        try {
            nextHierarchyIds = BanditManchotWhisperer.loadHistoryAndGetNextHierarchyIds(
                banditManchot[moduleId].instance,
                history
            );
        } catch (err) {
            Sentry.captureException(err, getContext(moduleId));
            nextHierarchyIds = {
                ...getRandomExercise(data, moduleId),
                isInitialTest: false,
            };
        }
        setBanditManchot({
            ...banditManchot,
            [moduleId]: {
                ...banditManchot[moduleId],
                historyLoaded: true,
            },
        });
    } else {
        try {
            nextHierarchyIds = BanditManchotWhisperer.getNextHierarchyId(
                banditManchot[moduleId].instance
            );
        } catch (err) {
            Sentry.captureException(err, getContext(moduleId));
            nextHierarchyIds = {
                ...getRandomExercise(data, moduleId),
                isInitialTest: false,
            };
        }
    }

    const [hierarchyList, setHierarchyList] = useState<Hierarchy[]>([
        getHierarchyFromHierarchyId(nextHierarchyIds, data),
    ]);
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    const [exerciseResults, setExerciseResults] = useState<
        ExerciseResult<any>[]
    >([]);
    const [currentTry, setCurrentTry] = useState<number>(1);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );
    const [
        currentExecutionStage,
        setCurrentExecutionStage,
    ] = useState<PlaylistExecutionStage>(
        PlaylistExecutionStage.PlayingCurrentExercise
    );
    const [isInitialTest, setIsInitialTest] = useState<boolean>(
        nextHierarchyIds.isInitialTest
    );

    // Reinit current exercise related information when current exercise
    // changes or another try starts
    useEffect(() => {
        setComingNext(undefined);
        setCurrentExerciseResult(undefined);
        setCurrentExecutionStage(PlaylistExecutionStage.PlayingCurrentExercise);
    }, [currentExerciseIndex, currentTry]);

    // Reinit current try when current exercise changes
    useEffect(() => {
        setCurrentTry(1);
    }, [currentExerciseIndex]);

    return {
        playlist: {
            module: hierarchyList[currentExerciseIndex].module,
            objective: hierarchyList[currentExerciseIndex].objective,
            activity: hierarchyList[currentExerciseIndex].activity,
            exercises: hierarchyList.map((hierarchy) => hierarchy.exercise),
            currentExercise: hierarchyList[currentExerciseIndex].exercise,
            currentTry,
            currentExerciseResult,
            isInitialTest,
            comingNext: comingNext,
            exerciseResults: exerciseResults,
            currentExecutionStage,
        },

        recordCurrentExerciseResult: (partialExerciseResult) => {
            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                exerciseId: hierarchyList[currentExerciseIndex].exercise.id,
                try: currentTry,
                feedback:
                    hierarchyList[currentExerciseIndex].exercise.feedback[
                        currentTry - 1
                    ][partialExerciseResult.correct ? "correct" : "incorrect"],
                activityId: hierarchyList[currentExerciseIndex].activity!.id,
            };
            setCurrentExerciseResult(exerciseResult);
            setExerciseResults((curr) => [...curr, exerciseResult]);
            const whatsComingNext = getWhatsComingNext(
                currentExerciseIndex,
                hierarchyList.map((hierarchy) => hierarchy.exercise),
                currentTry,
                exerciseResult
            );
            setComingNext(whatsComingNext);
            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );

            // Update history
            if (whatsComingNext !== "retry") {
                const historyItem = {
                    exerciseId: hierarchyList[currentExerciseIndex].exercise.id,
                    activityId: hierarchyList[currentExerciseIndex].activity.id,
                    objectiveId:
                        hierarchyList[currentExerciseIndex].objective.id,
                    score: partialExerciseResult.score
                        ? partialExerciseResult.score / currentTry
                        : partialExerciseResult.correct
                        ? 1 / currentTry
                        : 0,
                };
                try {
                    const newHierarchyIds = BanditManchotWhisperer.updateHistoryAndGetNextHierarchyIds(
                        banditManchot[moduleId].instance,
                        historyItem
                    );
                    setHierarchyList((curr) => [
                        ...curr,
                        getHierarchyFromHierarchyId(newHierarchyIds, data),
                    ]);
                    if (isInitialTest && !newHierarchyIds.isInitialTest)
                        // Timeout to show message after chatbot thinking animation delay
                        setTimeout(() => {
                            setCurrentExecutionStage(
                                PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                            );
                        }, 1000);
                    setIsInitialTest(newHierarchyIds.isInitialTest);
                } catch (err) {
                    Sentry.captureException(err, getContext(moduleId));
                    nextHierarchyIds = {
                        ...getRandomExercise(data, moduleId),
                        isInitialTest: false,
                    };
                }
                localStorageUtils.updateModuleHistory(moduleId, historyItem);
            }
        },

        goToNextExercise: () => {
            if (!comingNext)
                throw new PlayerProgressionError(
                    "goToNextExercise called before validating exercise"
                );

            if (comingNext === "retry") setCurrentTry(currentTry + 1);
            else setCurrentExerciseIndex(currentExerciseIndex + 1);
        },

        goToExercise: () => {
            throw new PlayerProgressionError(
                "Students cannot navigate to a specific exercise"
            );
        },

        clearHistory: () => {
            setExerciseResults([]);
        },
    };
};

const getWhatsComingNext = (
    currentExerciseIndex: number,
    exerciseList: Exercise<any, any>[],
    currentTry: number,
    exerciseResult: ExerciseResult<any>
): PlaylistManager["playlist"]["comingNext"] => {
    if (!exerciseResult) return undefined;

    // For backwards compatibility
    const numberOfTries =
        exerciseList[currentExerciseIndex].executionOptions?.numberOfTries ||
        exerciseList[currentExerciseIndex]?.numberOfTries;

    if (!exerciseResult.correct && currentTry < numberOfTries!) return "retry";
    return "nextExercise";
};

const getContext = (moduleId: string): CaptureContext => {
    return {
        contexts: {
            user: {
                token: localStorage.getItem("TOKEN")!,
            },
            "Bandit Manchot": {
                moduleId,
                history: JSON.stringify(
                    localStorageUtils.getCurrentUser()!.history[moduleId]
                ),
            },
        },
    };
};

export default useBanditManchotPlaylistManager;
