import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import { useContext, useEffect, useState } from "react";
import { configStore } from "../contexts/ConfigContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { dataStore } from "../contexts/DataContext";
import { homeStore } from "../contexts/HomeContext";
import { Config } from "../interfaces/Config";
import { ContentPage } from "../interfaces/ContentPage";
import { RawData, Data } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ErrorLog, Status } from "../interfaces/Status";
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";
import { applyTheme, Theme } from "../utils/theme-handler";
import windowLogger from "../utils/window-logger";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import chatbotTheme from "./chatbotTheme";
import config from "../config";
import aiConfig from "./aiConfig";
import { getActivitiesInModule } from "../utils/dataRetrieval";
import { banditManchotStore } from "../contexts/BanditManchotContext";

export interface GlobalConfig {
    config: Config;
    home: Home;
    contentPages: ContentPage[];
    theme: Theme;
}

export default function useSetContexts() {
    const { setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { setConfig } = useContext(configStore);
    const { setBanditManchot } = useContext(banditManchotStore);

    const [status, setStatus] = useState<Status>({
        type: "pending",
        errors: [],
    });

    useEffect(() => {
        const errorLog: ErrorLog[] = [];
        function getJson<T>(url: string, headers?: Headers): Promise<T> {
            return fetch(url, { cache: "no-cache", headers: headers })
                .then((response) => {
                    return response.json() as Promise<T>;
                })
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    errorLog.push({ info: err, type: "JSON" });
                    return err;
                });
        }

        async function getAllJson() {
            /* TEMP */
            let client;
            let hostname = window.location.hostname.split(".")[1]; // TEMP workaround
            hostname = hostname === "adaptivlangue" ? "adlang" : hostname;
            if (hostname === "localhost") {
                client = localStorage.getItem("client");
            } else {
                client = hostname;
                localStorage.setItem("client", client);
            }
            /* --- */


            /*
                Check localStorage for TOKEN key
                    - TOKEN is true 
                        * call ressources MS with that token
                    - TOKEN is false
                        * call demo MS to get a demo token
                        * set a new TOKEN key in localStorage which value is demo token
                        * call ressources MS with that token
            */
            const isToken = localStorage.getItem('TOKEN') ? true : false;

            // TEMP : STANDALONE only, remove for other version of the app
            localStorage.setItem('userType', 'STUDENT');

            // LOCAL ONLY : custom headers to call auth demo MS with a local url
            let customHeaders = new Headers({
                "x-evb-origin":"https://specimen.adaptivlangue.evidenceb.com"
            })
               
            // TOKEN is not set, call demo MS to get one then set it in localStorage
            if (!isToken) {
                let requestedToken = await getJson<{token:string}>("https://athena-auth.staging.evidenceb-services.com/v1/login/adlang/demo", customHeaders)
                localStorage.setItem('TOKEN', encodeURIComponent(requestedToken.token))
            }

            // Sequence of async fetch
            // Call Ms Ressources to get config.json
            const global = await getJson<GlobalConfig>(
                config.configJsonUrl ??
                   'https://athena-content-access.staging.evidenceb-services.com/content?token='+localStorage.getItem('TOKEN')+'&target=config&version=adlang/default/idf-diag-visible', customHeaders
            );
            /*  const global = (await import("./globalConfig")).default; */

            // Call Ms Ressources to get data.json
            const data = removeNonVisible(
                await getJson<RawData>(
                    config.dataJsonUrl ??
                        /* `${process.env.REACT_APP_CONTENT_ACCESS_URL}​/content?token=${token?.token}​&target=questions&version=adlang/default/idf-diag-visible` */
                        'https://athena-content-access.staging.evidenceb-services.com/content?token='+localStorage.getItem('TOKEN')+'&target=questions&version=adlang/default/idf-diag-visible', customHeaders
                )
            );
            registerDebugUtils(data);

            const exercises = await getExercisesWithAvailableGameplays(
                data.exercises,
                (exercise, reason) => {
                    windowLogger.error(
                        `Exercise could not be imported: ${reason}`,
                        exercise.id
                    ); 
                }
            );

            // TODO: Use actual config global.config.ai.config instead of aiConfig
            // Retrieve and instanciate AI
            const banditManchot = await BanditManchotWhisperer.initBanditManchot(global.config.ai!, {...data, exercises})

            // if no error is catch, set content and send fetched status, if error(s) send error status and log to display appropriate error page
            if (errorLog.length === 0) {
                applyTheme({ ...global.theme, chatbot: chatbotTheme }); //TODO: fallback theme, theme errors handler
                setConfig({...global.config, ai: aiConfig});
                setHome(global.home);
                setContentPages(global.contentPages);
                setData({
                    modules: data.modules,
                    objectives: data.objectives,
                    activities: data.activities,
                    exercises,
                });
                setBanditManchot(banditManchot);
                setStatus({ type: "fetched", errors: [] });
            } else {
                setStatus({ type: "error", errors: errorLog });
            }
        }

        getAllJson();
    }, [setConfig, setContentPages, setData, setHome]);

    return { status };
}


function removeNonVisible(data: RawData): RawData {
    const exercises = data.exercises.filter(isVisible);
    const existsInExercises = existsIn(exercises);
    const activities = data.activities.filter(isVisible).map((activity) => ({
        ...activity,
        exerciseIds: activity.exerciseIds.filter(existsInExercises),
    }));
    const existsInActivities = existsIn(activities);
    const objectives = data.objectives.filter(isVisible).map((objective) => ({
        ...objective,
        activityIds: objective.activityIds.filter(existsInActivities),
    }));
    const existsInObjectives = existsIn(objectives);
    const modules = data.modules.filter(isVisible).map((module) => ({
        ...module,
        objectiveIds: module.objectiveIds.filter(existsInObjectives),
    }));
    return { modules, objectives, activities, exercises };
}

function isVisible(object: { visibilityStatus: VisibilityStatus }): boolean {
    return object.visibilityStatus === VisibilityStatus.Visible;
}

function existsIn(elements: { id: string }[]) {
    const ids = new Set(elements.map((element) => element.id));
    return (id: string): boolean => ids.has(id);
}

function registerDebugUtils(data: RawData) {
    (window as any).DEBUG_UTILS = {
        getModuleById: (id: string) =>
            data.modules.find((module) => module.id === id),
        getObjectiveById: (id: string) =>
            data.objectives.find((objective) => objective.id === id),
        getActivityById: (id: string) =>
            data.activities.find((activity) => activity.id === id),
        getExerciseById: (id: string) =>
            data.exercises.find((exercise) => exercise.id === id),
    };
}
