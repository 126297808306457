import React, { useRef, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Page } from "../../interfaces/Config";
import "./Header.scss";

interface HeaderProps {
    logo: string;
    pages: Page[];
}

function Header({ logo, pages }: HeaderProps) {
    const menuContainer = useRef<HTMLDivElement>(null);

    const toggleMobileMenuHandler = () => {
        menuContainer.current?.classList.toggle("show");
    };
    const logoutHandler = () => {
        toggleMobileMenuHandler();
    };

    const location = useLocation();
    const [url, setUrl] = useState<string>()
    useEffect(() => {
        setUrl(location.pathname)
    }, [location])

    return (
        <header className="header-top">
            <div className="container">
                <div className="logo">
                    <Link to={`/`}>
                        <img src={logo} />
                    </Link>
                </div>
                <button
                    onClick={toggleMobileMenuHandler}
                    className="mobileMenuBtn"
                >
                    <span className="material-icons">dehaze</span>
                </button>
                <div ref={menuContainer} className="menu-container">
                    <nav>
                        {pages.map((page, i) =>
                            // TODO find a better way to detect STUDENT when implemented in the final version of the app
                            page.type !== "LOGOUT" && page.inTopNav && ( localStorage.getItem("userType") !== "STUDENT") ? (
                                <li
                                    onClick={toggleMobileMenuHandler}
                                    key={`navItem-${i}`}
                                >
                                    <NavLink exact to={`/${page.url}`}>
                                        {page.label}
                                    </NavLink>
                                </li>
                            ) : page.type === "LOGOUT" /* TEMP:disable only for STANDALONE*/ /*&& page.inTopNav  */ ? (
                                // TODO find a better way to code this
                                url !== '/' ? 
                                <button
                                    key={`navItem-${i}`}
                                    /* onClick={logoutHandler} */
                                >
                                    {/*TODO: redirect to a logout page for the main app; STANDALONE ONLY => a simple redirect to home page*/}
                                    <Link to={`/`}>Sortir
                                        {/* <span className="material-icons">
                                            power_settings_new
                                        </span> */}
                                    </Link>
                                </button>
                                : null
                            ) : null
                        )}
                    </nav>
                    <button
                        onClick={toggleMobileMenuHandler}
                        className="closeMobileMenuBtn"
                    >
                        <span className="material-icons">clear</span>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
